body{
    font-family: 'Mulish', sans-serif;
    font-size: 1rem;
    line-height: 1.2;
    color: #353d4d;
}
a, button, input, select, textarea{
    text-decoration: none !important;
    outline: none !important;
}
a, button{
    transition: all ease-in-out 300ms;
}
a{
    color: inherit;
}
.rubik-font{
    font-family: 'Rubik', sans-serif;
}
.mulish-font{
    font-family: 'Mulish', sans-serif;
}

h1, h2, h3, h4, h5, h6{
    margin-top: 0;
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
}
p{
    font-size: 1.125rem;
    line-height: 1.5556;
}
ul, ol{
    font-size: 1.125rem;
    line-height: 1.5556;
}

.back-to-top {
    border-radius: 5px;
    text-align: center;
    background: #000;
    color: #fff;
    display: none;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    opacity: 0.4;
    filter: alpha(opacity=40);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    cursor: pointer;
    z-index: 9999;
}
.back-to-top i {
    position: relative;
    top: 2px;
}
.back-to-top:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}

#header .navbar{
    padding: 1rem 15px;
}
#header.floating{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 991;
}
.navbar-brand{
    padding-top: 0;
    padding-bottom: 0;
}
.navbar-nav .nav-link{
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    color: #393f4f;
    padding-top: .72rem;
    padding-bottom: .72rem;
}
.navbar-nav .nav-item.nav-btn .nav-link{
    background: #edf0f5;
    color: #143aa3;
    border-radius: 25px;
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
}
.navbar-nav .nav-item.nav-btn .nav-link:hover{
    color: #edf0f5 !important;
    background: #143aa3;
}


.home-banner{
    position: relative;
    overflow: hidden;
    padding-top: 268px;
    padding-bottom: 3.125rem;
}
.home-banner .container{
    position: relative;
    z-index: 9;
}
.home-banner .h-banner{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 85%;
    height: auto;
}


.h-banner-content h1{
    font-size: 3.625rem;
    line-height: 1.2414;
    margin-bottom: 3rem;
    text-transform: uppercase;
    color: #143aa3;
}
.h-banner-content p{
    font-size: 1.375rem;
    line-height: 1.6364;
    letter-spacing: -0.01em;
    margin-bottom: 3.2102rem;;
}
.h-banner-content .button{
    min-width: 194px;
}

.button{
    display: inline-block;
    background: #fff;
    font-size: 1rem;
    border-radius: 24px;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    padding: .8rem 1.56rem;
    line-height: 1.2;
    color: #353d4d !important;
    border: 1px solid #dce0e5;
    text-align: center;
}
.button-large{
    font-size: 1.25rem;
    padding: 1.123rem 3.125rem;
}
.button-primary{
    background: #f98145;
    border-color: #f98145;
    color: #fff !important;
}
.button-primary:hover{
    background: #fa6f44;
    border-color: #fa6f44;
}

.button-secondary{
    background: #edf1f6;
    border-color: #edf1f6;
    color: #143aa3 !important;
}
.button-secondary:hover{
    background: #3257b5;
    border-color: #3257b5;
    color: #fff !important;
}

.accredited{
    padding-top: 7.9375rem;
}
.accredited h3{
    color: #353d4d;
    font-family: 'Mulish', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 2.5714;
    margin-bottom: 0.0893rem;;
}

.section-title{
    font-size: 3.1875rem;
    line-height: 1.4118;
    text-transform: uppercase;
    color: #143aa3;
    margin-bottom: 1rem;
}
.hs-2{
    padding-top: 150px;
    padding-bottom: 100px;
}
.hs-2 p{
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 4.2813rem;
}
.heading p{
    font-size: 1.25rem;
    line-height: 1.5;
}

#footer{
    padding-top: 90px;
    padding-bottom: 40px;
    background: #f6f6fa;
}
.footer-box{
    margin-bottom: 50px;
}
.copyright{
    padding-top: 1.8438rem;
    color: #8c94a5;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.03em;
}
.footer-box h2{
    font-size: 1rem;
    line-height: 1.2;
    margin-bottom: 1.2rem;
    text-transform: uppercase;
    color: #143aa3;
    font-weight: 500;
}
.footer-box ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.footer-box a{
    color: #393f4f;
}
.footer-box ul li{
    display: block;
    margin-bottom: 1.2rem;
}
.footer-box ul li:last-child{
    margin-bottom: 0;
}
.footer-box ul li a{
    font-size: 1rem;
    line-height: 1.5;
}
.footer-box address{
    font-size: 1rem;
    line-height: 1.5;
}
.footer-box .social{
    padding-top: 20px;
}
.footer-box .social a{
    margin-right: 24px;
}

.parking-box{
    display: block;
    width: 100%;
    position: relative;
    padding-left: 154px;
    min-height: 216px;
    background: #f6f7f9;
}
.parking-box .image{
    position: absolute;
    top: 0;
    left: 0;
    width: 154px;
    height: 100%;
}
.parking-box .image img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.parking-box .content{
    padding: 45px 35px 35px;
}
.parking-box .price{
    font-size: 1.625rem;
    line-height: 36px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
}
.parking-box .price strong{
    color: #143aa3;
}
.parking-box .price sub{
    color: #323232;
    font-size: 18px;
    position: relative;
    top: 0;
}
.parking-box .price sub:first-child{
    color: #143aa3;
}
.parking-box .button{
    padding: 0;
    text-align: center;
    font-size: 17px;
    height: 42px;
    line-height: 42px;
    min-width: 112px;
}
.parking-box h2{
    font-size: 1.625rem;
    line-height: 1.2;
    text-transform: uppercase;
}
.parking-box .head{
    min-height: 95px;
    position: relative;
}
.parking-box .head:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: url("../img/park-line.png") no-repeat top left;
    width: 50px;
    height: 2px;
    margin-bottom: 12px;
}
.park-index-row .grid-item{
    margin-bottom: 30px;
}

.button-group{
    margin-bottom: 100px;
}
.button-group .button{
    min-width: 154px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
}
.button-group .button:hover{
    background: #3257b5;
    color: #fff !important;
}
.button-group .button.active{
    background: #3257b5;
    color: #fff !important;
}
.separator{
    position: relative;
}
.separator img{
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}

.hs-3{
    position: relative;
    padding-top: 130px;
    padding-bottom: 70px;
}
.hs-3 .section-title{
    margin-bottom: 100px;
}

.why-box{
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 90px;
}
.why-box .icon{
    min-height: 7rem;
}
.why-box .icon .img{
    display: block;
    max-width: 100%;
    height: auto;
}
.why-box h3{
    font-size: 1.4375rem;
    line-height: 1.4783;
    letter-spacing: -0.005em;
    margin-bottom: 1.2rem;
    color: #143aa3;
    text-transform: uppercase;
}
.why-box p{
    font-size: 1.125rem;
    line-height: 1.6667;
}

.hs-4{
    position: relative;
    padding-top: 90px;
    padding-bottom: 90px;
}
.hs-4 .h-banner{
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}
.hs-4 .container{
    position: relative;
    z-index: 9;
}

.hs-4-content h2{
    font-size: 2.5rem;
    line-height: 1.25;
    letter-spacing: 0.03em;
    margin-bottom: 1.7969rem;
    text-transform: uppercase;
    color: #143aa3;
}
.hs-4-image{
    position: absolute;
    top: -32px;
    right: 0;
    z-index: 1;
}
.hs-4 .check-list li{
    font-size: 1.125rem;
    line-height: 2.6667;
}
.check-list{
    list-style: none;
    padding: 0;
    margin: 0;
}
.check-list li{
    display: block;
    position: relative;
    padding-left: 30px;
}
.check-list li:before{
    content: '';
    position: absolute;
    top: 1.1em;
    left: 0;
    background: url("../img/tick.png") no-repeat center;
    width: 12px;
    height: 10px;
}

.hs-5{
    padding-top: 190px;
    padding-bottom: 125px;
}
.hs-5 .section-title{
    position: relative;
    margin-bottom: 60px;
}
.hs-5 .section-title span{
    position: relative;
    z-index: 9;
}
.hs-5 .section-title:before{
    content: '';
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    background: url("../img/quot-icon.png") no-repeat center;
    width: 146px;
    height: 113px;
}

.testimonial-carousel{
    position: absolute;
    opacity: 0;
}
.testimonial-carousel.slick-initialized{
    position: relative;
    opacity: 1;
}
.testimonial-carousel .slick-slide{
    padding-left: 20px;
    padding-right: 20px;
    outline: none !important;
}

.testimonial-carousel .item{
    position: relative;
    display: block !important;
    width: 100%;
    padding: 20px;
    background: #f7f7fa;
    border-radius: 25px;
    opacity: .6;
    transition: all ease-in-out 300ms;
}
.testimonial-carousel .slick-active .item{
    opacity: 1;
}
.testimonial-carousel .item:before{
    content: '';
    position: absolute;
    bottom: -27px;
    left: 50px;
    background: url('../img/triangle.png') no-repeat top left;
    width: 40px;
    height: 54px;
}
.testimonial-carousel .item .content{
    margin-bottom: 10px;
    padding-bottom: 1px;
}
.testimonial-carousel .item .content:before{
    display: block;
    content: '';
    background: url('../img/quot-small.png') no-repeat top left;
    width: 18px;
    height: 15px;
    margin-bottom: 15px;
}
.testimonial-carousel .content p{
    font-size: 1.125rem;
    line-height: 1.5556;
}
.testimonial-carousel .cite h3{
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    font-size: 1.0625rem;
    line-height: 1.6471;
    color: #143aa3;
    margin-bottom: 2px;
}
.testimonial-carousel .cite p{
    margin-bottom: 0;
    color: #353d4d;
    font-weight: 600;
}
.testimonial-carousel-nav{
    padding-top: 50px;
}
.slick-arrow{
    display: inline-block;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    margin-left: 15px;
    margin-right: 15px;
}
.hs-6{
    position: relative;
    padding-top: 115px;
    padding-bottom: 95px;
    overflow: hidden;
}
.hs-6-box{
    display: block;
    background: #fff;
    position: relative;
    margin-bottom: 90px
}
.hs-6-bg{
    position: absolute;
    z-index: 1;
}
.hs-6-bg.hs-6-1{
    top: -110px;
    left: -125px;
}
.hs-6-bg.hs-6-2{
    bottom: -105px;
    right: -108px;
}
.hs-6-box .inner{
    position: relative;
    z-index: 9;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 7px;
    padding: 30px 15px;
}
.hs-6-box .heading{
    margin-bottom: 60px;
}
.input-wrapper{
    display: block;
    position: relative;
    margin-bottom: 25px;
}
.input-wrapper:after{
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    position: absolute;
    top: 17px;
    right: 20px;
}
.input-wrapper.valid:after{
    content: "\f00c";
    color: #143aa3;
}
.input-wrapper.valid .input{
    padding-right: 40px;
}
.input-wrapper.error:after{
    content: "\f12a";
    color: #e75353;
}
.input-wrapper.error .input{
    border-color: #e75353 !important;
    padding-right: 40px;
}
.input-wrapper label.error{
    font-size: 0.875rem;
    color: #e75353;
    margin: 0;
    padding-top: .8rem;
}

.input-label{
    display: block;
    width: 100%;
    margin: 0 0 .7rem !important;
    font-size: 1.0625rem;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    position: relative;
}
.input-label .indicator{
    float: right;
}
.input-label .indicator i{
    display: inline-block;
    margin-right: 0;
    margin-left: 3px;
    height: 3px;
    width: 16px;
    background: #35c8ce;
}
.input-label .indicator i:first-child{
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.input-label .indicator i:last-child{
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.input-wrapper .input-label span{
    color: #8c94a5;
    font-weight: 600;
    font-size: 0.9375rem;
}
.input-wrapper .input{
    display: block;
    width: 100%;
    height: 48px;
    line-height: 48px;
    padding: 0 24px;
    border-radius: 24px;
    border: 1px solid #dce0e5;
    color: #353d4d;
    font-size: 1rem;
    font-weight: 700;
    transition: all ease-in-out 300ms;
}
.input-wrapper textarea.input{
    min-height: 148px;
    line-height: 1.2;
    padding-top: 15px;
    padding-right: 15px;
}
.input-wrapper .input:hover{
    border-color: #c6cbd1;
}
.input-wrapper .input:focus{
    border-color: #143aa3;
}
.input-wrapper .input::placeholder{
    color: #8c94a5;
}

.park-index-wrapper{
    position: relative;
}
.park-index-wrapper.unfiltered{
    max-height: 0;
    overflow: hidden;
}

select.styled-select-menu{
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.select2-container .select2-selection--single{
    display: block;
    width: 100%;
    height: 48px !important;
    line-height: 48px !important;
    padding: 0 23px 0 23px;
    margin: 0;
    border: 1px solid #dce0e5;
    outline: none !important;
    background: none;
    transition: all ease-in-out 300ms;
    border-radius: 24px;
    font-size: 1rem;
    font-weight: 700;
}
.select2-container.select2-container--focus .select2-selection--single{
    border-color: #143aa3;
}
.select2-container .select2-selection--single:hover{
    border-color: #c6cbd1;
}
.select2-container .select2-selection--single .select2-selection__rendered{
    padding: 0 !important;
    position: relative;
    top: -1px;
}
.select2-selection__placeholder{
    display: block;
    color: #8c94a5;
    width: 100%;
    height: 48px;
    line-height: 48px;
    font-size: 1rem;
    font-weight: 700;
}
.select2-container.error .select2-selection--single, .select2-container .select2-selection--single.error{
    color: red;
}
.select2-container.error .select2-selection--single .select2-selection__placeholder,
.select2-container .select2-selection--single.error .select2-selection__placeholder{
    color: red;
}
.select2-dropdown{
    border: 1px solid #edeff2 !important;
    border-radius: 7px !important;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #fff;
}
.select2-results{
    background: #fff;
    box-shadow: none;
    border-radius: 0;
    max-height: 250px;
    overflow: auto;
}
.select2-results__option{
    padding: 10px 15px;
}
.select2-results__option[aria-selected="true"], .select2-results__option.select2-results__option--highlighted{
    background: #f0f3f5;
}
.select2-results__option{
    border-radius: 5px;
    transition: all ease-in-out 300ms;
}

.hs-6 .button-wrapper{
    padding-top: 30px;
}

.git-brands img{
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 25px;
}

.main-content{
    position: relative;
    padding-top: 60px;
    padding-bottom: 130px;
}

article a{
    color: #143aa3 !important;
    font-weight: 600;
}
.page-sub-title{
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 400;
}
.main-content article{
    max-width: 690px;
}
article .section-title{
    margin-bottom: 70px;
}
article p{
    margin-bottom: 30px;
}
article ul li, article ol li{
    margin-bottom: 20px;
}
article h2{
    font-family: 'Mulish', sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 25px;
}
article h2 span{
    color: #143aa3;
}
article li span{
    color: #143aa3;
    font-weight: 600;
}
strong, b{
    font-weight: 700;
}

.tabbed-list{
    list-style: none;
    padding: 0 0 0 30px;
    margin-bottom: 30px;
}

.side-nav ul{
    list-style: none;
    padding: 0;
    margin: 0 0 60px 0;
    border-left: 1px solid #dbdce7;
}
.side-nav ul li{
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 10px;
    position: relative;
}
.side-nav ul li:last-child{
    margin-bottom: 0;
}
.side-nav ul li a{
    display: block;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    padding-left: 30px;
    font-weight: 600;
}
.side-nav ul li.active a{
    color: #143aa3;
    font-weight: 700;
}
.side-nav ul li.active a:before{
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    width: 2px;
    height: 100%;
    background: #143aa3;
}

.login-wrapper{
    display: block;
    width: 100%;
    position: relative;
    min-height: 100vh;
    padding-right: 500px;
}
.login-wrapper .content{
    position: relative;
    min-height: 100vh;
}
.login-wrapper .image{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
    width: 500px;
    height: 100%;
    background-size: cover;
    background-position: center;
}
.login-wrapper .head{
    padding: 30px 50px;
    position: relative;
    z-index: 9;
}
.back-link{
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    line-height: 20px;
}
.back-link i{
    margin-right: 15px;
    font-size: 20px;
    line-height: 20px;
}
.login-wrapper .bottom{
    padding-bottom: 50px;
    font-family: 'Rubik', sans-serif;
}
.login-wrapper .bottom a{
    color: #f98145 !important;
}
.login-wrapper .center{
    
}
.login-wrapper .button-wrapper{
    padding-top: 20px;
    padding-bottom: 35px;
}
.button-block{
    display: block;
    width: 100%;
}
.login-form-wrapper{
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
}
.login-wrapper .forgot{
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
}

.form-page{
    background: #f6f7f8;
}
.form-page #header{
    background: #fff;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}
.nav-title{
    display: inline-block;
    height: 72px;
    line-height: 72px;
    border-left: 2px solid #e5e8ec;
    margin-left: 35px;
    padding-left: 45px;
    font-size: 1.625rem;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
}
.form-page .navbar-brand{
    margin-right: 0;
}

.form-section{
    padding-top: 80px;
    padding-bottom: 80px;
}
.form-section .elements{
    display: block;
    background: #fff;
    border-radius: 6px;
    padding: 30px;
}
.element-title{
    font-size: 1.75rem;
    line-height: 1;
    margin-bottom: 50px;
    text-transform: uppercase;
    color: #143aa3;
}
.select2-selection__arrow{
    position: absolute;
    top: 0;
    right: 20px;
    color: #717781;
}
.select2-selection__arrow b:after{
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 48px;
    font-weight: 300;
    font-size: 25px;
}
.select2-container--open .select2-selection__arrow b:after{
    transform: rotate(180deg);
}
.elements .input-wrapper{
    margin-bottom: 50px;
}

.elements .separator{
    height: 30px;
}

.group-label{
    font-size: 1rem;
    font-weight: normal;
    color: #adaeb6;
    margin-bottom: 25px;
}

.styled-checkbox-wrapper{
    display: inline-block;
    position: relative;
    line-height: 1.4;
    cursor: pointer;
    margin: 0 20px 20px 0;
    padding-left: 35px;
    font-family: 'Rubik', sans-serif;
    font-size: 0.9375rem;
}
.styled-checkbox-wrapper.block{
    display: block;
    width: 100%;
}
input.styled-checkbox{
    opacity: 0;
    position: absolute;
    z-index: -1;
}
.styled-checkbox{
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    height: 20px;
    width: 20px;
    line-height: 1.4;
    position: absolute;
    top: 0;
    left: 0;
    transition: all ease-in-out 300ms;
}
.iCheck-helper{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
}
.styled-checkbox.icheckbox{
    border: 1px solid #dce0e5;
}
.styled-checkbox-wrapper:hover .styled-checkbox.icheckbox{
    border-color: #c6cbd1;
}
.styled-checkbox.icheckbox.checked:after{
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    position: absolute;
    top: 3px;
    left: 4px;
    font-size: 11px;
    color: #143aa3;
}
.styled-checkbox.iradio{
    border: 1px solid #dce0e5;
    border-radius: 180%;
}
.styled-checkbox-wrapper:hover .styled-checkbox.iradio{
    border-color: #c6cbd1;
}
.styled-checkbox.iradio.checked:after{
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 180%;
    background: #143aa3;
    top: 0.324em;
    left: 0.35em;
}
.styled-checkbox-wrapper.toggle{
    padding-left: 55px;
}
.styled-checkbox-wrapper.toggle .styled-checkbox.icheckbox{
    width: 38px;
    border-radius: 20px;
    border: 1px solid #dce0e5;
    background: #edf1f6;
}
.styled-checkbox-wrapper.toggle .styled-checkbox.icheckbox:after{
    display: none;
}
.styled-checkbox-wrapper.toggle .styled-checkbox.icheckbox:before{
    content: '';
    position: absolute;
    top: 1px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 20px;
    background: #fff;
}
.styled-checkbox-wrapper.toggle .styled-checkbox.icheckbox.checked:before{
    left: auto;
    right: 2px;
}
.styled-checkbox-wrapper.toggle:hover .styled-checkbox.icheckbox{
    border-color: #e7eaef;
}
.styled-checkbox-wrapper.toggle .styled-checkbox.icheckbox.checked{
    border-color: #173ea0;
    background: #3257b5;
}
.styled-checkbox-wrapper.toggle:hover .styled-checkbox.icheckbox.checked{
    border-color: #173ea0;
    background: #173ea0;
}

.button-circle{
    padding: 0;
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
}

.button-link{
    border: 0;
    padding: 0;
    background: none;
    font-size: 15px;
}
.btn-tooltip{
    opacity: .5;
}
.btn-tooltip:hover{
    opacity: 1;
}
.button-small{
    font-size: 0.875rem;
    padding: .55rem 1.5rem;
    line-height: 1.2;
    height: auto;
}
.button-group.inline .button{
    margin-left: 0;
    margin-right: 0;
    min-width: 124px;
}
.elements .demo-btn{
    min-width: 148px;
}

.alert button{
    float: right;
    background: transparent;
    border: 0;
    padding: 0;
    opacity: .5;
}
.alert{
    border: 0;
    font-size: 0.875rem;
    padding: .875rem 1.25rem;
    border-radius: 6px;
}
.alert>i{
    margin-right: 5px;
}
.alert-success{
    background: #e0f7ec;
    color: #67c29f;
}
.alert-primary{
    background: #e3efff;
    color: #659bdd;
}
.alert-warning{
    background: #fff2cf;
    color: #dda924;
}
.alert-danger{
    background: #ffe1e1;
    color: #e75353;
}

.cp-single-section{
    padding-top: 60px;
    padding-bottom: 135px;
}

.map-wrapper{
    position: relative;
    height: 420px;
    background: #f4f6f9;
    margin-bottom: 70px;
}
.map-wrapper #map{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.img-fw{
    display: block;
    width: 100%;
    height: auto;
}
.park-image-wrapper{
    position: relative;
    overflow: hidden;
    padding: 1px;
    margin-bottom: 40px;
}
.park-image{
    position: relative;
}
.park-image img{
    display: block;
    width: 100%;
    height: auto;
}
.park-image:after{
    content: '';
    width: 100%;
    height: 83px;
    background: #fff;
    top: 100.4%;
    right: 11px;
    position: absolute;
    transform: rotate(5deg);
    transform-origin: 100% 100%;
    z-index: 9;
}
.blue-color{
    color: #143aa3;
}

.c-single-content h1{
    font-size: 2.875rem;
    line-height: 1.2609;
    color: #143aa3;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.c-single-content .head h5{
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 1.3125rem;
    line-height: 1.7143;
    margin-bottom: 50px;
}
.c-single-content p{
    font-size: 1.125rem;
    line-height: 1.5556;
    margin-bottom: 30px;
}
.cp-single-section h2{
    font-size: 1.375rem;
    line-height: 1.2727;
    margin-bottom: 20px;
    font-weight: 500;
}
.facilities{
    margin-bottom: 40px;
    padding-bottom: 1px;
}
.facilities h2{
    margin-bottom: 40px;
}
.fac-box{
    margin-bottom: 40px;
    font-size: 1.0625rem;
    line-height: 1.6471;
    color: #143aa3;
    font-weight: 600;
}
.fac-box .icon{
    height: 60px;
}
.fac-box .icon img{
    display: block;
    max-width: 100%;
    height: auto;
}

.side-box{
    margin-bottom: 40px;
    background: #f4f6f9;
    border-radius: 6px;
    padding: 15px;
}
.side-box .price {
    font-size: 1.875rem;
    line-height: 36px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
}
.side-box .price sub {
    color: #323232;
    font-size: 1.1875rem;
    position: relative;
    top: 0;
}
.side-box .price sub:first-child {
    color: #143aa3;
}
.side-box .price strong {
    color: #143aa3;
}
.side-box .sep{
    background: #e0e4e9;
    height: 1px;
    margin-top: 25px;
    margin-bottom: 25px;
}
.side-box table tr td{
    vertical-align: top;
    /*padding-bottom: 10px;*/
    font-size: 1.125rem;
    line-height: 1.8333;
    font-weight: 500;
}
.side-box table tr td span{
    color: #797f8f;
}
.side-box table tr td:first-child{
    width: 42px;
}
.side-box table tr:last-child td{
    margin-bottom: 0;
}

@media(min-width: 1300px){
    .navbar-nav .nav-link{
        padding-left: 1.125rem !important;
        padding-right: 1.125rem !important;
    }
    .navbar-nav .nav-link:hover{
        color: #143aa3;
    }
    .navbar-nav .nav-item.nav-btn{
        padding-left: 1.125rem;
    }
    .home-banner{
        min-height: 100vh;
    }
    .h-header-image{
        position: absolute;
        top: 140px;
        right: 0;
        z-index: 2;
    }
    .h-header-image.small{
        display: none;
    }
    .park-index-row{
        margin-left: -60px;
        margin-right: -60px;
    }
    .park-index-row>div{
        padding-left: 60px;
        padding-right: 60px;
    }
    .button-group .button{
        margin-left: 25px;
        margin-right: 25px;
    }
    .why-box{
        max-width: 230px;
    }
    .testimonial-carousel .slick-list{
        padding:0 27% 30px 27%;
    }
    .testimonial-carousel .item{
        padding: 40px 50px;
    }
    .git-brands img{
        margin-left: 45px;
        margin-right: 45px;
    }
    .form-section .elements{
        padding: 80px;
    }
    .map-wrapper{
        margin-right: -31px;
    }
    .c-single-content{
        max-width: 700px;
    }
}
@media(max-width: 1299px){
    .h-header-image.large{
        display: none;
    }
    .h-header-image.small{
        margin-top: 50px;
    }
    .accredited{
        padding-top: 0;
    }
    .h-header-image{
        display: block;
    }
    .home-banner .h-banner{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .hs-4-image{
        position: relative;
        top: 0;
        right: auto;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .hs-4 .h-banner{
        display: none;
    }
    .hs-4{
        background: #f6f6fa;
    }
}

@media(max-width: 1000px){
    .login-wrapper{
        padding-right: 0;
    }
    .login-wrapper .image{
        display: none;
    }
}

@media(min-width: 992px){
    #header .navbar{
        padding: 2.1875rem 3.75rem;
    }
    .hs-6-box .inner{
        padding: 70px 60px;
    }
    .lg-text-right{
        text-align: right;
    }
    .side-nav{
        padding-top: 50px;
    }
    .login-wrapper .bottom{

    }
    .side-box{
        padding: 30px;
    }
}
@media(max-width: 991px){
    .navbar-nav .nav-item.nav-btn .nav-link{
        text-align: center;
    }
    .navbar-collapse{
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        background: #fff;
        z-index: 991;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: -0.5rem;
    }
    .home-banner{
        padding-top: 150px;
    }
    .h-banner-content h1{
        font-size: 3.1rem;
    }
    .h-banner-content h1 br{
        display: none;
    }
    .section-title{
        font-size: 2.2rem;
    }
    .navbar-nav{
        padding-bottom: 15px;
    }
    .m-mb{
        margin-bottom: 30px;
    }
    .login-wrapper .head{
        padding-left: 15px;
        padding-right: 15px;
    }
    .login-wrapper .center {
        padding-top: 40px;
    }
}
@media(min-width: 768px){
    .col-md-20{
        flex: 0 0 20%;
        max-width: 20%;
    }
}
@media (max-width: 767px){
    .parking-box{
        padding-left: 0;
    }
    .parking-box .image{
        position: static;
        width: 100%;
        height: auto;
    }
    .parking-box .image img{
        max-width: 100%;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        object-fit: initial;
    }
    .button-group .button{
        min-width: 50px;
    }
}